import Sidebar from "../components/Sidebar";
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import { useState } from "react";
import Topbar from "../components/Topbar";
import DraftItems from "../components/DraftItems";
import Footer from "../components/Footer";

const DraftNews = () => {


    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const updateStartDate = () => {
        var startd = document.getElementById('startd').value;
        setStartDate(startd);
    }
    const updateEndDate = () => {
        var endd = document.getElementById('endd').value;
        setEndDate(endd);
    }

    return (
        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>

                <Sidebar />
                <Col md={10} lg={10} className="rightsect">
                    <h2 className="pagetitle">Draft News </h2>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label> From Date</Form.Label>
                                        <Form.Control
                                            size="md"
                                            type="date"
                                            selected={startDate}
                                            onChange={updateStartDate}
                                            id="startd"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label> To Date</Form.Label>
                                        <Form.Control
                                            size="md"
                                            type="date"
                                            selected={endDate}
                                            onChange={updateEndDate}
                                            id="endd"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col></Col>
                                <Col></Col>
                                {/*  <Col> 

                                    <Form.Group className="mb-3">
                                        <Button >Search</Button>
                                    </Form.Group>
                                </Col> */}

                            </Row>

                            <DraftItems startDate={startDate} endDate={endDate} />

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Footer />
        </Container>
    );
}
export default DraftNews;