import Sidebar from "../../components/Sidebar";
import { Container, Row, Col, Card, Form, Button, Table, Badge } from 'react-bootstrap';
import Topbar from "../../components/Topbar";
import Footer from "../../components/Footer";
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useState, useEffect } from "react";
import setting from '../../setting.json';

const Report = () => {
    const [month, setMonth] = useState([]);
    const [id, setId] = useState(0);
    const [data, setData] = useState([]);
    const getReportDate = async () => {
        var url = setting.api;
        await fetch(url + '/api/getReportDate/')
            .then(response => response.json())
            .then(json => {
                setMonth(json);
            }
            );
    }
    const updateReportId = () => {
        var rid = document.getElementById("reportid").value;
        setId(rid);
    }
    const getUserNewsData = async () => {
        var url = setting.api;
        const formData = new FormData();
        formData.append("id", id);
        await fetch(url + '/api/getUserNewsData/', {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(json => {
                setData(json);
                var dd = document.getElementById("reportid");
                var title = dd.options[dd.selectedIndex].getAttribute('title');
                document.getElementById("tabletitle").innerHTML = title;
            }
            );
    }

    useEffect(() => {
        getReportDate();
    }, []);

    return (
        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>
                <Sidebar />
                <Col md={10} lg={10} xs={12} className="rightsect">
                    <h3 className="pagetitle"><AddBoxIcon className="titleicon" /> Report Monthly(All User)</h3>
                    <Card >
                        <Card.Body>

                            <Row>


                                <Col md={2}>
                                    <Form.Group className="mb-3">
                                        <Form.Select aria-label="Default select example" id="reportid" onChange={updateReportId}>
                                            <option>Select Report</option>
                                            {month && month.map((r) =>
                                                <option value={r.id} key={r.id} title={`${r.month} ${r.year}`}>{`${r.month} ${r.year}`}</option>
                                            )}


                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                                <Col md={2}>
                                    <Form.Group className="d-grid gap-2">


                                        <Button variant="primary" size="md" onClick={getUserNewsData}>View</Button>


                                    </Form.Group>
                                </Col>
                            </Row>


                        </Card.Body>
                    </Card>



                    <Row>




                        <Col md={12} lg={12}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Report for <span id="tabletitle"></span></Card.Title>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Sr.No.</th>
                                                <th>Image</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Role</th>
                                                <th>Location</th>
                                                <th>No. Of News</th>
                                                <th>Status</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data && data.map((r) =>
                                                <tr key={r.num}>
                                                    <th>{r.num}</th>
                                                    <th><img width="30px" height="30px" src={r.image} alt="" /></th>
                                                    <th>{r.name}</th>
                                                    <th>{r.email}  </th>
                                                    <th>{r.email}</th>
                                                    <th>{r.location}</th>
                                                    <th> <Badge bg="primary">{r.news}</Badge></th>
                                                    <th>{r.status}</th>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                </Col>
            </Row>


            <Footer />

        </Container>

    );
}

export default Report;