import Sidebar from "../../components/Sidebar";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import setting from '../../setting.json';
import Topbar from "../../components/Topbar";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useState } from "react";

const schema = yup
    .object().shape({
        header_script: yup.string().required('Header script the required'),
        footer_script: yup.string().required('Footer Script the required'),
        meta_keyword: yup.string().required('Meta keyword the required'),
        meta_title: yup.string().required('Meta Title the required'),
        meta_desc: yup.string().required('Meta Description the required'),

    })

const Settings = () => {

    const {
        register,
        reset,
        formState: { errors },
        handleSubmit
    } = useForm({
        resolver: yupResolver(schema),
    });

    const Update = async (data) => {
        let ad = new FormData();
        ad.append('header_script', data.header_script);
        ad.append('footer_script', data.footer_script);
        ad.append('meta_keyword', data.meta_keyword);
        ad.append('meta_title', data.meta_title);
        ad.append('meta_desc', data.meta_desc);

        var url = setting.api;
        await fetch(url + '/api/updateSetting',
            {
                body: ad,
                method: "post",
                mode: "cors", // no-cors, *cors, same-origin
                headers: {
                    'Accept': 'application/FormData',
                }
            })
            .then(response => response)
            .then(data => {

            });
    }

    const getHeaderScript = async () => {
        var url = setting.api;
        await fetch(url + '/api/getSetting?key=header_script')
            .then(response => response.json())
            .then(data => {
                console.log(data);
                reset({
                    "header_script": data.value
                })
            });
    }
    const getFooterScript = async () => {
        var url = setting.api;
        await fetch(url + '/api/getSetting?key=footer_script')
            .then(response => response.json())
            .then(data => {
                console.log(data);
                reset({
                    "footer_script": data.value
                })
            });
    }
    const getCompanyProfile = async () => {
        var url = setting.api;
        await fetch(url + '/api/getCompanyProfile')
            .then(response => response.json())
            .then(data => {
                console.log(data);
                reset({
                    "meta_keyword": data.homekeyword,
                    "meta_desc": data.homedesc,
                    "meta_title": data.hometitle,

                })
            });
    }


    useEffect(() => {
        getHeaderScript();
        getFooterScript();
        getCompanyProfile();
    }, []);

    return (
        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>
                <Sidebar />
                {JSON.parse(localStorage.getItem('user')).role === 'admin' &&
                    <Col md={10} lg={10} className="rightsect">
                        <h2 className="pagetitle">Settings</h2>

                        <Row>
                            <Col>
                                <Form onSubmit={handleSubmit(Update)}>
                                    <Card>
                                        <Card.Header className="head">
                                            Update setting
                                        </Card.Header>
                                        <Card.Body>
                                            <Row>
                                                <Col md={3} lg={3}>
                                                    Header Script
                                                </Col>
                                                <Col md={9} lg={9}>
                                                    <Form.Control
                                                        as="textarea"
                                                        placeholder="Leave a comment here"
                                                        rows={4}
                                                        {...register("header_script")}
                                                        className="mb-3"
                                                    />
                                                    {errors.header_script && <p>{errors.header_script.message}</p>}

                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={3} lg={3}>
                                                    Footer Script
                                                </Col>
                                                <Col md={9} lg={9}>
                                                    <Form.Control
                                                        as="textarea"
                                                        placeholder="Leave a comment here"
                                                        rows={4}
                                                        {...register("footer_script")}
                                                        className="mb-3"
                                                    />
                                                    {errors.footer_script && <p>{errors.footer_script.message}</p>}


                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={3} lg={3}>
                                                    Home Meta Keyword
                                                </Col>
                                                <Col md={9} lg={9}>

                                                    <Form.Control
                                                        as="textarea"
                                                        placeholder="Leave a comment here"
                                                        {...register("meta_keyword")}
                                                        className="mb-3"

                                                    />


                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={3} lg={3}>
                                                    Home Meta Title( Max. 80 Chars.)
                                                </Col>
                                                <Col>

                                                    <Form.Control
                                                        as="textarea"
                                                        placeholder="Leave a comment here"
                                                        {...register("meta_title")}
                                                        className="mb-3"

                                                    />


                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={3} lg={3}>
                                                    Home Meta Description ( Max. 160 Chars.)
                                                </Col>
                                                <Col>

                                                    <Form.Control
                                                        as="textarea"
                                                        placeholder="Leave a comment here"
                                                        {...register("meta_desc")}
                                                        className="mb-3"

                                                    />


                                                </Col>
                                            </Row>
                                            <Button type='submit' variant='success'  >
                                                Update
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            </Col>
                        </Row>

                    </Col>
                }
            </Row >
        </Container >
    );
}
export default Settings;