import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import setting from "../setting.json";

const Login = () => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [logo, setLogo] = useState('');

    useEffect(() => {
        /* var loginstatus = JSON.parse(localStorage.getItem('user')).loggedin;
        console.log(loginstatus);

        if (loginstatus === 'true') {
            history.push('/dashboard');
        } */

    }, [history]);
    useEffect(() => {
        getCompanyProfile();

    }, []);

    const getCompanyProfile = async () => {
        var url = setting.api;

        await fetch(url + '/api/getCompanyProfile')
            .then(response => response.json())
            .then(json => {
                setLogo(json.logo);
                document.getElementsByTagName("LINK")[0].href = setting.api + '/wp-content/uploads/' + json.favicon;
                //setFavicon(json.favicon);

            }
            );
    }

    const updateEmail = () => {
        var em = document.getElementById('email').value;
        setEmail(em);
    }
    const updatePass = () => {
        var em = document.getElementById('pass').value;
        setPass(em);
    }
    const userLogin = (event) => {
        event.preventDefault()
        const formData = new FormData();
        formData.append("email", email);
        formData.append("pass", pass);
        var url = setting.api;
        fetch(url + '/api/userLogin', {
            method: 'POST',
            /* mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer', */
            body: formData
        })
            .then(response => response.json())
            .then(json => {
                //console.log(json);
                if (json.id != null) {
                    var userdata = JSON.stringify(json)
                    localStorage.setItem('user', userdata);
                    var token = window.btoa(userdata);
                    localStorage.setItem('token', token);
                    setting.licence = "abcdefgh12345678";
                    history.push('/dashboard');
                } else {
                    localStorage.setItem('loggedin', false);
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    document.getElementById('errormessage').innerHTML = "Email or password is incorrect";
                }
            }
            );
    }
    return (
        <Container fluid >

            <Row >
                <Col md={4} lg={4} ></Col>
                <Col md={4} lg={4} className="login">


                    <Card className="logincard" >
                        <Card.Body>
                            <Card.Header>

                                <h2 className="logintitle"><img src={`${setting.url}/wp-content/uploads/${logo}`} alt="" className="logologin" /></h2>
                            </Card.Header>
                            <Card.Title className="logintext">Please Login </Card.Title>
                            <Form onSubmit={userLogin}>
                                <p id="errormessage"></p>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" onChange={updateEmail} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="pass">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder="Password" onChange={updatePass} />
                                </Form.Group>

                                <Button variant="primary" type="submit" >
                                    Submit
                                </Button>
                                <p>Note : For Password Related Issue Contact Admin</p>
                            </Form>
                        </Card.Body>
                    </Card>

                </Col>
            </Row>

        </Container>
    );
}

export default Login;