import { Container, Row, Col, Card, Table, Button, Form, Badge } from 'react-bootstrap';
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import setting from '../../setting.json';
import Topbar from "../../components/Topbar";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup
    .object().shape({
        visibility: yup.string().required('Visibility the required'),
        status: yup.string().required('status the required'),
        from_date: yup.string().required('from_date the required'),
        to_date: yup.string().required('to_date the required'),
        image: yup.string(),
        alt_text: yup.string().required('alt_text the required'),
        url: yup.string(),
        ad_script: yup.string().required('ad_script the required'),
        id: yup.number(),

    })

const Advertisement = () => {

    const { register, reset, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });

    const [list, setList] = useState(null);
    const [record, setRecord] = useState(null);
    const [add, setAdd] = useState(true);


    const getAdvertisement = async () => {
        var url = setting.api;
        await fetch(url + '/api/getAllAdvertisement')
            .then(response => response.json())
            .then(json => {
                setList(json);
                var rec;
                json.filter((a) => {
                    if (a.id == 1) {
                        rec = a;
                    }
                })
                setRecord(rec);
            }
            );
    }

    const updateAdvertisement = async (data) => {
        let ad = new FormData();
        ad.append('id', data.id);
        ad.append('fromdate', data.from_date);
        ad.append('todate', data.to_date);
        ad.append('alt_text', data.alt_text);
        ad.append('url', data.url);
        ad.append('script', data.ad_script);
        ad.append('visibility', data.visibility);
        ad.append('status', data.status);
        var url = setting.api;
        await fetch(url + '/api/updateAdvertisement',
            {
                body: ad,
                method: "post",
                headers: {
                    'Accept': 'application/FormData',
                }
            })
            .then(response => response)
            .then(data => {
                setAdd(true);
                getAdvertisement();
            });
    }

    useEffect(() => {
        getAdvertisement();


    }, []);


    const getAdvertisementById = (id) => {
        setAdd(false);
        var rec;
        list.filter((a) => {
            if (a.id == id) {
                rec = a;

            }
        })
        setRecord(rec);

        reset({
            "visibility": rec.visibility,
            "status": rec.status,
            "ad_script": rec.script,
            "url": rec.url,
            "alt_text": rec.alt_text,
            "from_date": rec.fromdate,
            "to_date": rec.todate,
            "id": rec.id

        })
    }
    const getAdvertisementViewById = (id) => {
        setAdd(true);
        var rec;
        list.filter((a) => {
            if (a.id == id) {
                rec = a;

            }
        })
        setRecord(rec);


    }


    return (

        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>
                <Sidebar />
                <Col md={10} lg={10} className="rightsect">
                    <h2 className="pagetitle">{add ? "View" : "Edit"} Advertisement</h2>
                    <Row>
                        <Col md={5} lg={5}>

                            {add &&
                                < Card >
                                    <Card.Header className="head">
                                        View Adv ( <b>{record && record.title}</b> )
                                    </Card.Header>
                                    <Card.Body>


                                        <Row className="mb-3">
                                            <Form.Group >
                                                <Form.Label> <b>Ad Visibility :  </b>{record && record.visibility == '1' ? 'image' : 'script'}</Form.Label>
                                            </Form.Group>

                                            <Form.Group  >
                                                <Form.Label> <b>Status :</b> {record && record.status == '1' ? 'Active' : 'Inactive'}</Form.Label>
                                            </Form.Group>

                                            <Form.Group >
                                                <Form.Label> <b>From Date :</b> {record && record.fromdate}</Form.Label>
                                            </Form.Group>

                                            <Form.Group  >
                                                <Form.Label> <b>To Date :</b> {record && record.todate}</Form.Label>
                                            </Form.Group>

                                            <Form.Group >
                                                <Form.Label> <b>Alt Text :</b> {record && record.alt_text}</Form.Label>
                                            </Form.Group>

                                            <Form.Group  >
                                                <Form.Label> <b>URL :</b> {record && record.url}</Form.Label>
                                            </Form.Group>

                                            {record && record.visibility == '1' ?
                                                <Form.Group  >
                                                    <Form.Label> <img src={`${setting.api}/wp-content/uploads/adv/${record.image}`} width="100%" /></Form.Label>
                                                </Form.Group>
                                                :

                                                <Form.Group  >
                                                    <Form.Label> <b>Adv Script :</b> {record && record.script}</Form.Label>
                                                </Form.Group>
                                            }
                                        </Row>






                                    </Card.Body>
                                </Card>
                            }

                            {!add &&
                                <Form onSubmit={handleSubmit(updateAdvertisement)}>
                                    <Card>
                                        <Card.Header className="head">
                                            Edit Adv ( <b>{record && record.title}</b> )
                                        </Card.Header>
                                        <Card.Body>


                                            <Row className="mb-3">
                                                <Form.Group as={Col}>
                                                    <Form.Label>Ad Visibility</Form.Label>
                                                    <Form.Select aria-label="Default select example" {...register("visibility")}>
                                                        <option value="" >Select</option>
                                                        <option value="1" >Image</option>
                                                        <option value="2" >Script</option>

                                                    </Form.Select>
                                                    {errors.visibility && <p>{errors.visibility.message}</p>}
                                                </Form.Group>

                                                <Form.Group as={Col} >
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Select aria-label="Default select example" {...register("status")}>
                                                        <option value="">Select</option>
                                                        <option value="1">Active</option>
                                                        <option value="0">Inactive</option>

                                                    </Form.Select>
                                                    {errors.status && <p>{errors.status.message}</p>}
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col}>
                                                    <Form.Label>From Date</Form.Label>
                                                    <Form.Control type="date" {...register("from_date")} />
                                                    {errors.from_date && <p>{errors.from_date.message}</p>}
                                                </Form.Group>

                                                <Form.Group as={Col} >
                                                    <Form.Label>To Date</Form.Label>
                                                    <Form.Control type="date" {...register("to_date")} />
                                                    {errors.to_date && <p>{errors.to_date.message}</p>}
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">


                                                <Form.Group as={Col} >
                                                    <Form.Label>Image</Form.Label>
                                                    <Form.Control type="file" {...register("image")} />
                                                    {errors.image && <p>{errors.image.message}</p>}
                                                </Form.Group>
                                                <Form.Group as={Col} >
                                                    <Form.Label>Alt Text</Form.Label>
                                                    <Form.Control type="text" placeholder="Alt Text" {...register("alt_text")} />
                                                    {errors.alt_text && <p>{errors.alt_text.message}</p>}
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col}>
                                                    <Form.Label>URL</Form.Label>
                                                    <Form.Control type="text" placeholder="URL" {...register("url")} />
                                                    {errors.url && <p>{errors.url.message}</p>}
                                                </Form.Group>


                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col}>
                                                    <Form.Label>Ad Script</Form.Label>
                                                    <Form.Control as="textarea" {...register("ad_script")} />
                                                    {errors.ad_script && <p>{errors.ad_script.message}</p>}
                                                </Form.Group>

                                            </Row>
                                            <input type='hidden' {...register("id")} />
                                            <Button type='submit' variant='success'  >Update</Button>
                                        </Card.Body>
                                    </Card>
                                </Form>
                            }


                        </Col>
                        <Col md={7} lg={7}>
                            <Card>
                                <Card.Header className="head">
                                    List
                                </Card.Header>
                                <Card.Body>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Sr.No.</th>
                                                <th>Image</th>
                                                <th>Adv Title</th>
                                                <th>Adv Title</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list && list.map((r, index) => (
                                                <tr key={r.id}>
                                                    <td>{index + 1}</td>
                                                    <td><img src={`${setting.api}/wp-content/uploads/adv/${r.image}`} width="50px" /></td>
                                                    <td>{r.title}</td>
                                                    <td><Badge bg={r.status == '1' ? 'success' : 'danger'}>{r.status == '1' ? 'Active' : 'Inactive'}</Badge></td>
                                                    <td>
                                                        <Button variant="link" size="sm" onClick={() => getAdvertisementById(r.id)} ><EditIcon /></Button>{' '}
                                                        <Button variant="link" size="sm" onClick={() => getAdvertisementViewById(r.id)}
                                                        ><VisibilityIcon /></Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </Col>
            </Row >
            <Footer />
        </Container >
    );
}

export default Advertisement;