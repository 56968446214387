import Sidebar from "../components/Sidebar";
import { Container, Row, Col, Card } from 'react-bootstrap';
import Topbar from "../components/Topbar";
import Footer from "../components/Footer";
import AddBoxIcon from '@mui/icons-material/AddBox';

const Profile = () => {

    return (
        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>
                <Sidebar />
                <Col md={10} lg={10} xs={12} className="rightsect">
                    <h3 className="pagetitle"><AddBoxIcon className="titleicon" /> Coming Soon </h3>
                    <Row>

                        <Col md={12} >
                            <Card >
                                <Card.Body>
                                    <Card.Title>Coming Soon</Card.Title>

                                </Card.Body>
                            </Card>

                        </Col>
                    </Row>

                </Col>
            </Row>


            <Footer />

        </Container>

    );
}

export default Profile;