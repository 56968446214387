import { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Table, Modal } from 'react-bootstrap';
import Topbar from "../components/Topbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import AddBoxIcon from '@mui/icons-material/AddBox';
import setting from '../setting.json';
const Profile = () => {
    const [show, setShow] = useState(false);
    const [list, setList] = useState(null);

    const handleClose = () => setShow(false);


    const updateSitemap = async () => {
        var url = setting.api;

        await fetch(url + '/sitemap/updateSitemap/')
            .then(response => response.json())
            .then(json => {
                setShow(true);

            }
            );

    }
    const getSitemaps = async () => {
        var url = setting.api;

        await fetch(url + '/sitemap/getSitemaps/')
            .then(response => response.json())
            .then(json => {
                console.log(json);
                setList(json)

            }
            );

    }
    useEffect(() => {
        getSitemaps();
    }, []);
    return (



        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>

                <Sidebar />



                <Col md={10} lg={10} xs={12} className="rightsect">

                    <h3 className="pagetitle"><AddBoxIcon className="titleicon" /> Sitemap Setting</h3>

                    <Row>
                        <Col md={3} >
                            <Card >
                                <Card.Body>
                                    <Card.Title>Sitemap Setting</Card.Title>
                                    <p>
                                        On clicking the update sitemap, Sitemap will be update and submit in GOOGLE and all other search engine.
                                    </p>
                                    <Button variant="primary" type="submit" id="preview" onClick={updateSitemap}>Update </Button>
                                </Card.Body>
                            </Card>

                        </Col>
                        <Col md={9} xs={12}>
                            <Card >
                                <Card.Body>
                                    <Card.Title>Sitemap List</Card.Title>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>

                                                <th>File</th>
                                                <th>Start Id</th>
                                                <th>End Id</th>
                                                <th>Date</th>
                                                <th>Total URL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!list &&
                                                <tr>
                                                    <td id="norecord" colspan="6">No Record found</td>
                                                </tr>
                                            }
                                            {list && list.map((r) => (
                                                <tr key={r.id}>

                                                    <td><a target="_blank" rel="noreferrer">{r.name}</a></td>
                                                    <td>{r.start_id}</td>
                                                    <td>{r.end_id}</td>
                                                    <td>{r.created}</td>
                                                    <td>{r.total_url}</td>

                                                </tr>
                                            ))}




                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>



                        </Col>
                    </Row>

                </Col>
            </Row>


            <Footer />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Sitemap</Modal.Title>
                </Modal.Header>
                <Modal.Body>Sitemap Updated</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </Container>

    );
}

export default Profile;